body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body,
#root,
.ant-spin-nested-loading,
.ant-spin-container {
  height: 100%;
}
.ant-layout {
  min-height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.auth-form .btn-main {
  width: 100%;
}

.auth-form {
  max-width: 450px;
  margin: auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 6px;
}

.header .org-name {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 600;
}

/*Profile page styles*/
.profile-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-item.summary {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-item.btns {
  display: flex;
  align-items: center;
}
/**/
