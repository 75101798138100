@charset "utf-8";
/*======================================

 [ -Main Stylesheet-
  Theme: Gmag
  Version: 1.0
  Last change: 23/12/2021 ]
     ++ Fonts ++
	++ loader ++
	++ Typography ++
	++ General ++
	++ navigation ++
	++ footer ++
	++ button ++
	++ hero ++
	++ section ++
	++ post ++
	++ widget ++
	++ shop ++
	++ forms ++
	++ responsive ++
*/
/*-------------Import fonts ---------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;500;600;700;800;900&amp;display=swap");
/*-------------General Style---------------------------------------*/

.body {
  margin: 0;
  padding: 0;
  font-family: "Quicksand", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  background: #001a3f;
  min-height: 100%;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  text-align: center;
} 

/*--------------Typography--------------------------------------*/

/* ---------Content Styles--------------------------------------*/
#main {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  opacity: 1;
  background: #fff;
}
#wrapper {
  float: left;
  width: 100%;
  position: relative;
  padding-top: 140px;
}
.fl-wrap {
  float: left;
  width: 100%;
  position: relative;
  height: 100%;
}
.container {
  max-width: 1300px;
  width: 96%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.content {
  width: 100%;
  position: relative;
  z-index: 13;

  float: left;
}
.full-height {
  height: 100%;
}
.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-attachment: scroll;
  background-position: center;
  background-repeat: repeat;
  background-origin: content-box;
}
.respimg,
.single-slider img,
.single-slider-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
  z-index: 2;
}
.no-padding {
  padding: 0 !important;
}
.bg-wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
}
.gray-bg {
  background: #f9f9f9;
}
.content-banner-wrap {
  margin: 0 auto;
  max-width: 860px;
}

/*------ navigation  ------------------------------------------------*/

/*------  Section ------------------------------------------------------*/
section {
  float: left;
  width: 100%;
  position: relative;
  padding: 6px 0;
  z-index: 10;
  overflow: hidden;
}
#formLead {
  margin-top: 10px;
}

#formLead input {
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.05);
  padding: 0 20px;
  height: 40px;
  line-height: 50px;
  position: relative;
  z-index: 1;
  color: #fff;
  width: 100%;
  outline: none;
  float: left;
  box-sizing: border-box;
  font-family: "Quicksand", sans-serif;
}
#formLead input::-webkit-input-placeholder,
#formLead input::-webkit-input-placeholder {
  color: #fff;
  font-weight: 500;
  font-size: 11px;
  position: relative;
  font-family: "Quicksand", sans-serif;
}
#formLead .subscribe-button {
  float: right;
  width: 100%;
  z-index: 2;
  height: 40px;
  line-height: 40px;
  color: #fff;
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
  border-radius: 2px;
  background: #ed143d;
}

.copyright {
  font-weight: 500;
  font-size: 12px;
  color: #999;
  text-align: left;
  float: left;
  line-height: 36px;
}
.subfooter-nav {
  float: right;
}
.subfooter-nav li {
  float: left;
  margin-left: 10px;
  line-height: 36px;
}
.subfooter-nav li a {
  color: #fff;
}
.to-top {
  float: right;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px;
  color: #fff;
  margin-left: 20px;
  cursor: pointer;
  transition: all 200ms linear;
}
.to-top i {
  transition: all 200ms linear;
  transform: translateY(0px);
  position: relative;
}
.to-top:hover i {
  transform: translateY(-6px);
}
/*------login------------------------------------------------*/
.mg_logo {
  position: absolute;
  z-index: 3;
  top: 50%;
  height: 30px;
  margin-top: -15px;
  right: 0;
  left: 0;
}
.mg_logo:before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100px;
  left: 50%;
  top: 50%;
  margin: -50px 0 0 -50px;
  background: rgba(255, 255, 255, 0.05);
  z-index: 1;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 26px rgb(255 255 255 / 10%);
}
.mg_logo img {
  height: 30px;
  width: auto;
  position: relative;
  z-index: 2;
}
.main-register-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: auto;
  display: none;
  -webkit-transform: translate3d(0, 0, 0);
  background: rgba(0, 0, 0, 0.6);
}
.reg-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  -webkit-transform: translate3d(0, 0, 0);
}
.main-register-holder {
  max-width: 820px;
  margin: 150px auto;
  position: relative;
  z-index: 2;
}
.main-register-wrap {
  padding-left: 400px;
  background: #fff;
  overflow: hidden;
  border-radius: 6px;
  transition: all 0.4s ease-in-out;
  transform: scale(0.8);
  opacity: 0;
}
.main-register_bg {
  position: absolute;
  left: 0;
  width: 400px;
  top: 0;
  bottom: 0;
}
.main-register {
  float: left;
  width: 100%;
  position: relative;
  padding: 100px 50px 30px;
  background: #fff;
  overflow: hidden;
  box-shadow: 0 15px 5px rgba(0, 0, 0, 0.08);
}
.vis_mr {
  opacity: 1;
  transform: scale(1);
}
.main-register .tabs-menu {
  position: absolute;
  left: 0;
  top: 0;
  right: 50px;
}
.close-modal {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  font-size: 15px;
  transition: all 0.4s ease-in-out;
}
.close-modal:hover {
  background: #f1f1f1;
}
.main-register .tabs-menu li {
  float: left;
  width: 50%;
}
.main-register .tabs-menu li a {
  height: 50px;
  line-height: 50px;
  float: left;
  width: 100%;
  background: #292929;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  color: #fff;
}
.main-register .tabs-menu li.current a,
.main-register .tabs-menu li.current a i {
  color: #fff;
}
.main-register .tabs-menu li a i {
  margin-right: 10px;
  font-weight: 800;
}
.soc-log {
  padding: 5px 0;
}
.soc-log p {
  margin-bottom: 12px;
  font-size: 11px;
  color: #454545;
  text-align: center;
}
.soc-log a {
  float: left;
  width: 100%;
  color: #fff;
  height: 50px;
  line-height: 50px;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 600;
  position: relative;
  text-align: center;
  background: #292929;
}
.soc-log a i {
  font-size: 12px;
  position: absolute;
  top: 0;
  width: 50px;
  height: 50px;
  left: 0;
  line-height: 50px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}
.log-separator span {
  position: relative;
  width: 90px;
  display: inline-block;
  background: #fff;
  z-index: 2;
}
.log-separator:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background: #eee;
  z-index: 1;
}
.custom-form .log-submit-btn {
  float: left;
  padding: 18px 0;
  width: 100%;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-appearance: none;
  margin-bottom: 16px;
  font-family: "Quicksand", sans-serif;
}
.custom-form .log-submit-btn:hover {
  color: #000;
}
.custom-form label {
  float: left;
  position: relative;
  width: 100%;
  text-align: left;
  font-weight: 500;
  color: #666;
  font-size: 11px;
  margin-bottom: 6px;
}
.main-register .filter-tags {
  float: left;
  margin: 2px 0 0 0;
}
.main-register .filter-tags input {
  float: left;
  position: relative;
  border: 1px solid #e5e7f2;
  cursor: pointer;
  padding: 0;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  color: #fff;
  background: #fff;
  -webkit-appearance: none;
}
.filter-tags input:checked:after {
  font-family: Font Awesome\5 Pro;
  content: "\f00c";
  font-size: 12px;
  position: absolute;
  top: 2px;
  left: 3px;
  z-index: 20;
}
.main-register .filter-tags label {
  color: #50596e !important;
  width: auto;
  margin: 2px 0 0 12px;
}
.lost_password {
  margin-top: 4px;
  float: right;
}
.lost_password a {
  float: left;
  color: #666;
  font-size: 11px;
  font-weight: 500;
}
.lost_password a:hover {
  text-decoration: underline;
}
.tbhc_btn,
.mob-hid_btn {
  display: none;
}
.stp-bot {
  padding-bottom: 50px;
}
/*------ post ------------------------------------------------*/
.bold_gpi,
.gpi_light {
  margin-bottom: 0px;
  padding: 10px;
  background: #001a3f;
}
.bold_gpi:after {
  content: "";
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 10px;
  z-index: -1;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 10px 14px 0 rgb(37 44 65 / 12%);
  transition: all 0.3s ease;
}
.bold_gpi .grid-post-content {
  border: 1px solid #eee;
  padding: 20px 20px 0px;
  background: #fff;
}
.grid-post-content h3 {
  text-align: left;
  font-weight: 600;
  font-size: 15px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
}
.grid-post-media {
  overflow: hidden;
  position: relative;
  border-radius: 4px 4px 0 0;
  height: 220px;
}
.grid-post-media a.gpm_link {
  float: left;
  width: 100%;
  height: 100%;
}
.grid-post-media.gpm_sing {
  border-radius: 4px;
  padding: 75px 0;
  height: auto;
}
.grid-post-media_title {
  position: absolute;
  bottom: 0;
  z-index: 20;
  left: 0;
  right: 0;
  padding: 10px 28px 15px;
}
.grid-post-media_title .post-opt {
  margin-left: 20px;
  color: #fff;
  top: 6px;
  position: relative;
  float: left;
}
.grid-post-media_title .post-opt i,
.grid-post-media_title .post-opt li {
  margin-right: 10px;
}
.gpm_sing .author-link {
  position: absolute;
  z-index: 10;
  left: 40px;
  top: 30px;
}
.grid-post-media_title h4 {
  text-align: left;
  color: #fff;
  float: left;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
}
.grid-post-media_title h4 a,
.gpm_sing .author-link a {
  color: #fff;
}
.gpi_light .grid-post-media {
  margin-bottom: 15px;
}
.grid-post-media .bg {
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.grid-post-media:hover .bg {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}
.grid-post-content .post-date,
.list-post-content .post-date {
  float: left;
  width: 100%;
  text-align: left;
  color: #666;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 3px 0 10px 0;
}
.grid-post-content .post-date i,
.post-opt li i,
.list-post-content .post-date i {
  margin-right: 4px;
}
.post-opt li i {
  font-weight: 600;
}
.bold_gpi .grid-post-footer {
  background: #fff;
  border: 1px solid #eee;
  border-top: none;
  border-radius: 0 0 4px 4px;
  overflow: hidden;
  padding: 16px 20px;
}
.post-opt li {
  float: left;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
}
.author-link {
  float: left;
}
.author-link img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  float: left;
  margin-right: 8px;
}
.author-link span {
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
  top: 9px;
}
.author-link strong {
  color: #777;
}
.author-link:hover strong {
  color: #000;
}
.grid-post-footer .post-opt {
  float: right;
  position: relative;
  top: 12px;
}
.grid-post-footer .post-opt li {
  margin-right: 10px;
  color: #000;
}
.grid-post-footer .post-opt li span,
.grid-post-footer .post-opt li a {
  color: #000;
  margin-right: 2px;
}
.grid-post-item .post-category-marker {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
.grid-post-media_title .post-category-marker {
  position: relative;
  top: 0;
  right: 0;
  float: left;
  margin-bottom: 15px;
}
.grid-post-item .post-category-marker:hover {
  background: #292929;
}
.gs-controls {
  position: absolute;
  bottom: 30px;
  right: 30px;
  left: 50px;
  z-index: 10;
}
.gs-controls:before {
  content: "";
  position: absolute;
  left: 0;
  right: 120px;
  height: 1px;
  bottom: 18px;
  background: rgba(255, 255, 255, 0.2);
}
.gs_button {
  float: right;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  margin-left: 6px;
}
.gc-pagination.pag-style {
  float: right;
}
.gc-pagination.pag-style .swiper-pagination-bullet {
  background: #bbb;
}
.gc-pagination.pag-style .swiper-pagination-bullet:before {
  border-color: #ccc;
}
.sgs_ver {
  position: absolute;
  right: 15px;
  left: auto;
  bottom: 20%;
  z-index: 50;
  width: 10px;
  transform: translateY(50%);
}
.sgs_hor {
  position: absolute;
  bottom: 35px;
  z-index: 50;
  width: 100%;
}
.single-grid-slider-wrap {
  margin: 10px 0;
}
.sgs-pagination .swiper-pagination-bullets,
.multi-pag .swiper-pagination-bullets {
  width: 10px;
}
.sgs-pagination .swiper-pagination-bullet,
.multi-pag .swiper-pagination-bullet,
.hero-carousel-pag .swiper-pagination-bullet {
  width: 7px;
  height: 7px;
  background: #fff;
  opacity: 1;
  position: relative;
}
.sgs_ver .swiper-pagination-bullet,
.hero-carousel-pag .swiper-pagination-bullet {
  margin-bottom: 10px;
}
.sgs_hor .swiper-pagination-bullet {
  margin: 0 8px;
}
.gallery-item {
  width: 33.3%;
  position: relative;
  float: left;
  padding: 5px;
}
.list-post {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #eee;
}
.list-post:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 1px;
}
.list-post-media {
  position: relative;
  width: 40%;
  overflow: hidden;
  height: 190px;
  float: left;
  border-radius: 4px;
}
.post-media_title {
  position: absolute;
  right: 0;
  bottom: -50px;
  z-index: 20;
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  padding: 6px 13px;
  border-radius: 4px 0 0 0;
  transition: all 400ms linear;
}
.list-post-media:hover .post-media_title,
.grid-post-media:hover .post-media_title {
  bottom: 0;
}
.list-post-media a {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}
.list-post-content {
  float: left;
  width: 60%;
  padding: 3px 0 0 30px;
  position: relative;
}
.list-post-content:before,
.single-post-header:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.list-post-wrap_column .list-post-media,
.list-post-wrap_column .list-post-content {
  width: 100%;
}
.list-post-wrap_column .list-post-media {
  height: 240px;
}
.list-post-wrap_column_fw.list-post-wrap_column .list-post-media {
  height: 375px;
}
.list-post-wrap_column_fw.list-post-wrap_column .list-post-content {
  padding: 22px 0 0 0;
}
.list-post-wrap_column .list-post-content {
  padding: 12px 0 0 0;
}
.list-post-wrap_column .list-post-content:before {
  display: none;
}
.list-post-wrap_column_fw.list-post-wrap_column .list-post-content:before {
  display: block;
  top: 22px;
}
.list-post-wrap_column .list-post {
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.list-post-wrap_column_fw.list-post-wrap_column .list-post {
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.cen-align-container {
  padding-left: 30px;
  border-left: 1px solid #eee;
}
.list-post-content h3 {
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  display: block;
  width: 100%;
  clear: both;
}
.list-post-wrap_column_fw.list-post-wrap_column .list-post-content h3 {
  font-size: 24px;
}
.list-post-content .post-opt li {
  margin-right: 10px;
}
.list-post-content .post-opt {
  margin-top: 20px;
  float: right;
}
.list-post-content .post-category-marker,
.single-post-header .post-category-marker {
  float: left;
  margin-bottom: 6px;
}
.list-post-wrap_column .post-category-marker {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
}
.list-post-content .post-category-marker,
.grid-post-media_title .post-category-marker {
  padding: 4px 13px;
  font-size: 10px;
}
.list-post-content .post-category-marker:hover {
  background: #222;
}
.single-post-header {
  padding: 0 0 30px;
  border-bottom: 1px solid #eee;
}
.single-post-header h1 {
  font-size: 28px;
  font-weight: 700;
  text-align: left;
}
.single-post-header h4 {
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #6c6f72;
  font-family: "Quicksand", sans-serif;
  padding-bottom: 20px;
}
.single-post-header .post-opt {
  float: right;
  padding: 6px 14px 6px 5px;
  background: #f9f9f9;
  border: 1px solid #eee;
}
.single-post-header .post-opt li {
  margin-left: 15px;
  font-size: 11px;
}
.single-post-header .post-date {
  float: left;
  margin: 10px 0 0 20px;
  position: relative;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
}
.single-post-header .post-date i {
  margin-right: 6px;
}
.ss-slider-cont {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-top: -20px;
  border-radius: 4px;
  color: #fff;
  z-index: 50;
  cursor: pointer;
}
.ss-slider-cont-next {
  right: 20px;
}
.ss-slider-cont-prev {
  left: 20px;
}
.pmd_vis {
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
}
.box-media-zoom {
  position: absolute !important;
  right: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  line-height: 40px;
  font-size: 14px;
  background: rgb(32 32 32 / 0%);
  color: #fff;
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  z-index: 100;
}
.hov_zoom:hover .box-media-zoom {
  top: 0;
}
.spc_column:before {
  content: "";
  position: absolute;
  left: 86px;
  height: 100%;
  width: 1px;
  background: #eee;
  top: 0;
}
.ss-slider-controls2 {
  position: absolute;
  left: 20px;
  z-index: 20;
  bottom: 20px;
}
.single-post-media {
  margin-bottom: 20px;
}
.single-post-media .video-holder {
  border-radius: 6px;
  height: 450px;
}
.spc_column {
  padding-left: 120px;
}
.mb_head {
  font-size: 18px;
  text-align: left;
  padding-bottom: 10px;
  font-weight: 600;
}
.has-drop-cap:first-letter {
  font-size: 70px;
  float: left;
  padding: 20px 20px 40px 0;
  font-family: "Quicksand", sans-serif;
  color: #000;
}
.single-post-content_text_media {
  margin: 15px 0;
}
.single-post-content_text_media img {
  border-radius: 4px;
}
.single-post-content_text p {
  font-size: 12px;
  font-weight: 400;
  color: #000;
}
.single-post-content_column {
  position: absolute;
  top: 0;
  left: 0;
}
.print-btn {
  float: right;
  margin: 10px 20px 0 0;
  padding: 8px 20px;
  background: #f9f9f9;
  border: 1px solid #eee;
}
.print-btn i {
  font-size: 16px;
  margin-left: 10px;
}
.print-btn span {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  top: -2px;
}
.fontSize {
  float: left;
  width: 350px;
  padding: 0 50px 15px 120px;
  position: relative;
  background: #f9f9f9;
  border: 1px solid #eee;
  overflow: hidden;
  border-radius: 4px;
}
.fs-wrap {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
  z-index: 20;
}
.fs_title {
  position: absolute;
  left: 15px;
  top: 19px;
  font-size: 13px;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
}
.fontSize input {
  float: left;
}
.fontSize .irs-single {
  display: none;
}
.fontSize .irs-line,
.fontSize .irs-line-left,
.fontSize .irs-line-mid,
.fontSize .irs-bar,
.fontSize .irs-line-right,
.fontSize .irs-bar-edge {
  height: 2px;
}
.fontSize .irs-max,
.fontSize .irs-min {
  visibility: visible !important;
  top: 20px;
  color: #333;
  font-size: 10px;
}
.fontSize .irs-slider:before {
  width: 14px;
  height: 14px;
  margin: -7px 0 0 -7px;
}
.fontSize .irs-min {
  left: -35px;
}
.fontSize .irs-max {
  right: -36px;
}
.show-more-snopt {
  float: right;
  font-size: 24px;
  height: 36px;
  line-height: 36px;
  width: 36px;
  border: 1px solid #eee;
  background: #f9f9f9;
  color: #000;
  cursor: pointer;
  border-radius: 2px;
  position: relative;
  margin-top: 10px;
}
.smact_vis i:before {
  content: "\f00d";
}
.smact_vis {
  font-size: 12px;
}
.show-more-snopt-tooltip {
  position: absolute;
  right: 0;
  width: 190px;
  background: #f9f9f9;
  top: 100%;
  margin-top: 30px;
  z-index: 20;
  border: 1px solid #eee;
  overflow: hidden;
  padding: 5px 10px;
  border-radius: 4px;
  transition: all 300ms linear;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 5px 5px rgb(0 0 0 / 6%);
}
.show-more-snopt-tooltip.show-more-snopt-tooltip_vis {
  margin-top: 0;
  opacity: 1;
  visibility: visible;
}
.show-more-snopt-tooltip a {
  display: block;
  border-bottom: 1px solid #ddd;
  padding: 10px;
  text-align: left;
  font-size: 11px;
  color: #000;
  font-weight: 600;
  position: relative;
}
.show-more-snopt-tooltip a i {
  margin-right: 10px;
}
.show-more-snopt-tooltip a:last-child {
  border-bottom: 0;
}
.single-post-footer {
  margin-top: 15px;
  padding-top: 25px;
  border-top: 1px solid #eee;
}
.post-single-tags,
.tags-title,
.post-single-tags .tags-widget {
  float: left;
}
.tags-title {
  margin: 10px 20px 0 0;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
}
.tags-title i {
  margin-right: 10px;
}
.post-author {
  float: left;
  background: #f9f9f9;
  border: 1px solid #eee;
  margin: 40px 0;
  border-radius: 4px;
  overflow: hidden;
}
.author-content {
  padding: 30px 35px 30px 130px;
}
.author-img {
  position: absolute;
  z-index: 2;
  top: 30px;
  left: 25px;
}
.author-img img {
  width: 80px;
  height: 80px;
  border-radius: 4px;
  float: left;
  box-shadow: 0 15px 40px rgb(0 0 0 / 37%);
}
.author-content h5 {
  text-align: left;
  color: #666;
  float: left;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 15px;
}
.author-content p {
  text-align: left;
}
.profile-card-footer_soc {
  float: right;
}
.profile-card-footer_soc .profile-card-footer_title {
  margin-right: 25px;
}
.post-author_link {
  float: left;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  margin-left: 30px;
}
.post-author_link i {
  margin-left: 10px;
}
.single-post-comm {
  margin-top: 20px;
}
.comment {
  padding: 0 0 30px 100px;
  position: relative;
  float: left;
  margin-bottom: 40px;
  width: 100%;
  border-bottom: 1px solid #eee;
}
.comment_reply {
  float: right;
  width: calc(100% - 90px);
}
.comment_reply:before {
  content: "";
  position: absolute;
  top: -10px;
  left: -60px;
  width: 40px;
  height: 40px;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.comment-author {
  position: absolute;
  top: 0;
  left: 10px;
  width: 80px;
  height: 80px;
}
.comment-author:before {
  content: "";
  position: absolute;
  right: -14px;
  top: 20px;
  width: 8px;
  height: 8px;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  transform: rotate(45deg);
  background: #f9f9f9;
  z-index: 10;
}
.comment-author img {
  width: 60px;
  height: 60px;
  float: left;
  border-radius: 50%;
}
.comment-body {
  float: left;
  width: 100%;
  position: relative;
  padding: 25px 30px;
  background: #f9f9f9;
  border-radius: 2px;
  border: 1px solid #eee;
}
.comment-body h4 {
  text-align: left;
  float: left;
  font-size: 15px;
  font-weight: 600;
  color: #666;
  margin-bottom: 15px;
}
.comment-body .star-rating {
  float: left;
  margin: 5px 0 0 20px;
  font-size: 10px;
}
.comment-body p {
  text-align: justify;
}
.comment-meta {
  float: left;
  top: 20px;
  position: relative;
  text-transform: uppercase;
  font-size: 10px;
  color: #000;
  font-weight: 500;
}
.box-widget-menu-btn {
  position: absolute;
  right: 30px;
  top: 25px;
  cursor: pointer;
  z-index: 20;
  font-size: 16px;
}
.comment-body .show-more-snopt-tooltip {
  background: #fff;
  top: 60px;
}
.comment-meta i {
  margin-right: 10px;
}
.comment-reply-link {
  float: right;
  padding: 8px 20px;
  background: #292929;
  font-weight: 500;
  font-size: 11px;
  margin-top: 10px;
  color: #fff;
  border-radius: 2px;
}
.comment-reply-link i {
  padding-right: 10px;
  font-size: 10px;
}
.comment-body_dec {
  position: absolute;
  bottom: 40px;
  right: 150px;
  left: 180px;
  height: 1px;
  background: #d9d9d9;
}
.add-comment .btn {
  padding: 18px 30px;
}
.pr-subtitle {
  float: left;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin-bottom: 30px;
}
.pr-subtitle i {
  margin-left: 10px;
}
.pr-subtitle.prs_big {
  font-size: 18px;
  position: relative;
  width: 100%;
  text-align: left;
}
.pr-subtitle.prs_big:before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 70%;
  height: 1px;
  background: #eee;
  z-index: 1;
}
.pr-subtitle.prs_big:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  width: 50px;
  height: 1px;
  z-index: 2;
}
.spn_box {
  float: left;
  width: 50%;
  position: relative;
}
.single-post-nav {
  padding: 25px 0;
  margin-top: 25px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}
.single-post-nav:before {
  content: "";
  position: absolute;
  top: 20px;
  bottom: 20px;
  width: 1px;
  background: #eee;
  z-index: 0;
}
.single-post-nav_prev {
  padding: 0 20px 0 110px;
}
.single-post-nav_next {
  padding: 0 110px 0 20px;
}
.spn_box_img {
  position: absolute;
  top: 0;
  width: 90px;
}
.spn_box_img img {
  border-radius: 4px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 0%);
  transition: all 200ms linear;
  transform: translateY(0);
}
.spn_box:hover img {
  box-shadow: 0 5px 5px rgb(0 0 0 / 15%);
  transform: translateY(-4px);
}
.single-post-nav_prev .spn_box_img {
  left: 0;
}
.single-post-nav_next .spn_box_img {
  right: 0;
}
.spn-box-content_subtitle {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  color: #666;
  display: block;
  padding-bottom: 4px;
}
.spn-box-content_title {
  font-size: 13px;
  font-weight: 600;
  display: block;
}
.single-post-nav_prev .spn-box-content_subtitle,
.single-post-nav_prev .spn-box-content_title {
  text-align: left;
}
.single-post-nav_next .spn-box-content_subtitle,
.single-post-nav_next .spn-box-content_title {
  text-align: right;
}
.single-post-nav_prev .spn-box-content_subtitle i {
  margin-right: 10px;
}
.single-post-nav_next .spn-box-content_subtitle i {
  margin-left: 10px;
}
.fs-carousel-wrap {
  padding-left: 400px;
  background: #111;
  overflow: hidden;
}
.fs-carousel-wrap_title {
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  height: 100%;
  padding: 0 50px;
}
.fs-carousel-wrap_title-wrap {
  text-align: left;
  color: #fff;
  top: 50%;
  transform: translateY(-50%);
}
.fs-carousel-wrap_title-wrap h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 12px;
  position: relative;
}
.fs-carousel-wrap_title-wrap h4:before {
  content: "";
  position: absolute;
  top: -30px;
  left: 0;
  width: 40px;
  height: 2px;
}
.fs-carousel-wrap_title .abs_bg {
  top: auto;
  bottom: 0;
  opacity: 0.1;
}
.fs-carousel .bold_gpi {
  margin-bottom: 0;
  border-radius: 0;
}
.fs-carousel .grid-post-media.gpm_sing {
  padding: 250px 0;
  height: auto;
  border-radius: 0;
}
/*------ btn ------------------------------------------------------*/
.ver-share {
  margin-top: 20px;
}
.share-holder.hor-share {
  float: right;
}
.share-holder.hor-share .share-title {
  float: left;
  font-size: 13px;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  text-align: left;
  position: relative;
  margin: 15px 20px 0 0;
}
.ver-share .share-title {
  margin-bottom: 20px;
  font-size: 13px;
  font-family: "Quicksand", sans-serif;
  font-weight: 600;
  text-align: left;
  position: relative;
}
.ver-share .share-title:before {
  content: "";
  position: absolute;
  left: 0;
  top: -10px;
  height: 2px;
  width: 20px;
}
.ver-share .share-container {
  width: 50px;
  float: left;
  border: 1px solid #eee;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 5px 30px rgb(0 0 0 / 5%);
}
.share-holder.hor-share .share-container {
  float: left;
  border: 1px solid #eee;
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 5px 30px rgb(0 0 0 / 5%);
}
.share-icon {
  float: left;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #fff;
  font-size: 12px;
  transition: all 100ms linear;
}
.share-holder.hor-share .share-container .share-icon {
  border-right: 1px solid #eee;
}
.ver-share .share-container .share-icon {
  border-bottom: 1px solid #eee;
}
.ver-share .share-container .share-icon:last-child,
.share-holder.hor-share .share-container .share-icon:last-child {
  border: none;
}
.share-icon:hover {
  color: #fff;
  border-color: transparent;
}
.share-icon:before {
  font-family: Font Awesome\5 Brands;
}
.share-icon-digg:before {
  content: "\f1a6";
}
.share-icon-rss:before {
  content: "\f09e";
}
.share-icon-facebook:before {
  content: "\f39e";
}
.share-icon-twitter:before {
  content: "\f099";
}
.share-icon-tumblr:before {
  content: "\f173";
}
.share-icon-linkedin:before {
  content: "\f0e1";
}
.share-icon-pinterest:before {
  content: "\f231";
}
.share-icon-stumbleupon:before {
  content: "\f1a4";
}
/*------ btn ------------------------------------------------------*/
.btn {
  padding: 14px 6px;
  color: #fff;
  border-radius: 2px;
  background: #292929;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  float: left;
  width: 50%;
  margin: 10px 0;
  min-width: 116px;
}
.float-btn {
  float: left;
}
.btn i,
.dark-btn i {
  margin-left: 4px 2px 4px 0px;
}
.dark-btn {
  display: inline-block;
  height: 50px;
  line-height: 50px;
  padding: 0 90px;
  background: #222;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  font-size: 12px;
  margin-top: 10px;
  border-radius: 4px;
}
.btn-orange {
  background: #de480a;
}
/*------ widgets ------------------------------------------------------*/
.categories_nav-inner {
  float: left;
  overflow: hidden;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
}
.categories_nav-inner ul {
  margin: 0;
  float: left;
  width: 100%;
  overflow: hidden;
}
.categories_nav-inner li {
  margin: 0;
  position: relative;
  float: left;
  width: 100%;
  margin-bottom: 4px;
}
.categories_nav-inner a {
  float: left;
  padding: 0 0 0 20px;
  border: 1px solid #eee;
  background: #f5f5f5;
  height: 54px;
  line-height: 54px;
  position: relative;
  font-weight: 700;
  font-size: 10px;
  text-align: left;
  color: #000;
  text-transform: uppercase;
  z-index: 2;
  width: 100%;
}
.categories_nav-inner a:hover,
.categories_nav-inner a.back {
  color: #fff;
  background: #222;
  border-color: transparent;
}
.categories_nav-inner a i {
  margin-right: 10px;
  font-size: 13px;
}
.categories_nav-inner a.back {
  margin-bottom: 10px;
}
.categories_nav-inner a.back:after {
  content: "Back";
  color: #fff;
  padding-left: 20px;
}
.categories_nav-inner a.back:before {
  font-family: Font Awesome\5 Pro;
  content: "\f104";
  color: #fff;
}
.categories_nav-inner .submen-dec:before {
  font-family: Font Awesome\5 Pro;
  content: "\f0da";
  position: absolute;
  right: 20px;
  width: 10px;
  height: 10px;
  top: 19px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.371);
  z-index: 20;
  font-weight: 600;
}
.sidebar-content {
  border-left: 1px solid #eee;
}
.box-widget {
  margin-bottom: 40px;
  padding-left: 30px;
}
.sidebar-content .box-widget:last-child {
  margin-bottom: 0;
}
.widget-title {
  text-align: left;
  font-size: 18px;
  font-weight: 700;
  color: #292929;
  padding-bottom: 15px;
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
  position: relative;
}
.widget-title:before {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 70px;
  height: 1px;
}
.tags-widget a {
  float: left;
  padding: 12px 20px;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  border-radius: 2px;
  margin: 0 5px 5px 0;
  font-weight: 600;
  background: #222;
}
.slider_widget_title {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  z-index: 20;
  color: #fff;
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  font-family: "Quicksand", sans-serif;
}
.slider_widget_title:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  width: 40px;
  height: 2px;
  margin-left: -20px;
}
.slider_widget .grid-post-media_title {
  padding: 10px 20px 70px;
}
.slider_widget .grid-post-media.gpm_sing:before {
  display: none;
}
.slider_widget .grid-post-media.gpm_sing {
  padding: 230px 0;
}
.slider_widget .grid-post-media_title h4 {
  text-align: center;
  font-size: 16px;
}
.slider_widget .grid-post-media_title .video-date {
  width: 50%;
  text-align: center;
}
.slider_widget .grid-post-media_title .post-opt {
  width: 50%;
  margin: 0;
  padding-left: 10px;
}
.slider_widget .grid-post-media_title .post-category-marker {
  display: inline-block;
  float: none;
}
.social-widget a {
  float: left;
  width: 48%;
  margin: 0 2% 2% 0;
  padding: 20px 4px 0 66px;
  color: #000;
  border-radius: 2px;
  height: 60px;
  background: #f5f5f5;
  border: 1px solid #eee;
}
.slider_widget {
  position: relative;
}
.social-widget span {
  font-weight: 600;
  float: left;
  margin-right: 5px;
}
.social-widget a i {
  font-size: 15px;
  position: absolute;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #fff;
  left: 8px;
  top: 50%;
  margin-top: -20px;
  box-shadow: 0 5px 5px rgb(0 0 0 / 6%);
  transition: all 200ms linear;
}
.social-widget a:hover i {
  box-shadow: 0 0px 0px rgb(0 0 0 / 6%);
}
.soc-widget-title {
  color: #6c757d;
}
.facebook-soc i {
  color: #3c579c;
}
.twitter-soc i {
  color: #39a1f8;
}
.instagram-soc i {
  color: #c862dc;
}
.sb-categories_bg_item {
  position: relative;
  display: block;
  padding: 25px 60px 25px 30px;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 4px;
  background: #f5f5f5;
  border: 1px solid #eee;
}
.spb-categories_counter {
  position: absolute;
  top: 50%;
  right: 20px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  margin-top: -18px;
  border-radius: 2px;
  color: #fff;
  z-index: 10;
  font-weight: 600;
  overflow: hidden;
}
.spb-categories_counter:before {
  font-family: Font Awesome\5 Pro;
  content: "\f0da";
  position: absolute;
  width: 36px;
  height: 36px;
  line-height: 36px;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -18px;
  z-index: 10;
  opacity: 0;
  background: #fff;
}
.sb-categories_bg_item:hover .spb-categories_counter:before {
  opacity: 1;
}
.sb-categories_bg_item .bg {
  -webkit-transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1) 0ms;
}
.sb-categories_bg_item:hover .bg {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  transform: scale(1.15);
}
.spb-categories_title {
  text-align: left;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  float: left;
  padding: 6px 30px 6px 10px;
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  position: relative;
  border-radius: 2px;
  color: #fff;
  z-index: 10;
}
.spb-categories_title span {
  padding-right: 6px;
}
.cbw_mar {
  margin: 30px auto;
}
#footer-twiit {
  text-align: left;
}
#footer-twiit a {
  color: #000;
}
#footer-twiit .tweet_text {
  color: #666;
  float: left;
  padding: 20px;
  border: 1px solid #eee;
  background: #f9f9f9;
  font-family: "Quicksand", sans-serif;
  font-size: 12px;
  border-radius: 4px;
  overflow: hidden;
  font-weight: 600;
  margin: 10px 0;
}
.tweet_join {
  display: none;
}
.interact {
  float: right;
}
.tweet_time {
  float: left;
  margin-left: 10px;
  font-size: 10px;
  text-transform: uppercase;
}
#footer-twiit .interact a:hover {
  color: #aaa;
}
#footer-twiit .timePosted a {
  color: #666;
  font-family: "Quicksand", sans-serif;
  font-style: italic;
  position: relative;
  float: left;
  margin-bottom: 30px;
  padding-left: 20px;
  font-size: 12px;
}
#footer-twiit .timePosted a:before {
  font-family: Font Awesome\5 Brands;
  content: "\f081";
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
}
#footer-twiit .timePosted a:after {
  top: -10px;
  left: 22px;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
#footer-twiit .timePosted a:after {
  border-color: transparent;
  border-top-color: rgba(255, 255, 255, 0.11);
  border-width: 7px;
  margin-left: 7px;
}
.tabs-widget .tabs-menu {
  overflow: hidden;
  border-radius: 4px 4px 0 0;
  border: 1px solid #eee;
}
.tabs-widget .tabs-menu li {
  float: left;
  width: 50%;
}
.tabs-widget .tabs-menu li a {
  background: #222;
  box-sizing: border-box;
  display: block;
  padding: 15px 0;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
}
.tabs-widget .tabs-menu li.current a {
  color: #fff;
}
.tab-content .post-widget-container {
  padding: 25px 20px;
  background: #f9f9f9;
  border: 1px solid #eee;
  border-top: none;
}
.post-widget-item {
  padding-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #ddd;
}
.post-widget-container .post-widget-item:first-child {
  padding-top: 0;
  border-top: none;
}
.post-widget-container .post-widget-item:last-child {
  margin-bottom: 0;
}
.post-widget-item-media {
  float: left;
  width: 30%;
  overflow: hidden;
  border-radius: 4px;
}
.post-widget-item-media a,
.grid-post-media a {
  position: relative;
  display: block;
}
.post-widget-item-media a:before,
.list-post-media a:before,
.grid-post-media a.gpm_link:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 200ms linear;
}
.post-widget-item-media a:after,
.list-post-media a:after,
.grid-post-media a.gpm_link:after {
  font-family: Font Awesome\5 Pro;
  content: "\f178";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 10px;
  height: 10px;
  line-height: 10px;
  margin: -5px 0 0 -5px;
  z-index: 3;
  color: #fff;
  opacity: 0;
  transition: all 200ms linear;
}
.list-post-media a:after,
.grid-post-media a.gpm_link:after {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: -20px 0 0 -20px;
  border-radius: 50%;
  font-size: 10px;
  box-shadow: 0px 0px 0px 4px rgb(255 255 255 / 40%);
}
.post-widget-item-media a:hover:before,
.post-widget-item-media a:hover:after,
.list-post-media a:hover:before,
.list-post-media a:hover:after,
.grid-post-media a.gpm_link:hover:before,
.grid-post-media a.gpm_link:hover:after {
  opacity: 1;
}
.post-widget-item-media img {
  width: 100%;
  height: auto;
}
.post-widget-item-content {
  float: left;
  width: 70%;
  position: relative;
  padding: 6px 0 0 15px;
}
.post-widget-item-content h4 {
  text-align: left;
  font-weight: 600;
  padding-bottom: 4px;
  font-size: 13px;
}
.pwic_opt li {
  float: left;
  margin-right: 8px;
  color: #666;
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
}
.pwic_opt li i {
  margin-right: 4px;
  font-weight: 900;
}
.pwic_opt li:first-child i {
  font-weight: 400;
}
.search-widget input.search {
  background: #eee;
  border-radius: 4px 0 0 4px;
  position: relative;
  color: #000;
  -webkit-appearance: none;
}
.search-widget input.search::-webkit-input-placeholder,
.search-widget input.search::-webkit-input-placeholder {
  color: #000;
}
.search-submit2 {
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 50px;
  font-size: 11px;
  color: #fff;
  border-radius: 0 4px 4px 0;
}
.bg-parallax-wrap-gradien:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 90%
  );
}
.banner-widget {
  padding: 180px 30px 20px;
  overflow: hidden;
  border-radius: 4px;
}
.banner-widget_content {
  z-index: 2;
  position: relative;
}
.banner-widget_content h5 {
  text-align: left;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  position: relative;
}
.banner-widget_content .btn {
  margin-top: 15px;
}
.cat-wid-list {
  float: left;
  width: 100%;
  position: relative;
}
.cat-wid-list li {
  float: left;
  width: 100%;
  text-align: left;
  padding-bottom: 8px;
  margin-bottom: 15px;
  position: relative;
  color: #292929;
}
.cat-wid-list li:last-child {
  margin-bottom: 0;
}
.cat-wid-list li a {
  color: #999;
  font-size: 12px;
  padding: 4px 20px 0 0;
  font-weight: 500;
  float: left;
  background: #fff;
  position: relative;
  z-index: 2;
}
.cat-wid-list li a:hover {
  color: #000;
}
.cat-wid-list li:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 50px;
  z-index: 1;
  border-top: 1px dotted #ccc;
}
.cat-wid-list li span {
  float: right;
  width: 26px;
  height: 26px;
  line-height: 26px;
  color: #fff;
  font-weight: 600;
  border-radius: 2px;
  text-align: center;
  font-size: 10px;
}
.profile-card-wrap {
  overflow: hidden;
  border: 1px solid #eee;
  background: #f9f9f9;
  border-radius: 6px;
}
.profile-card_media {
  padding: 55px 30px 40px;
  position: relative;
}
.profile-card_media:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: #f9f9f9;
  z-index: 1;
}
.profile-card_media img {
  width: 80px;
  height: 80px;
  position: relative;
  z-index: 2;
  border-radius: 4px;
  float: left;
  top: 10px;
  box-shadow: 0 15px 40px rgb(0 0 0 / 37%);
}
.profile-card_media_content {
  float: left;
  padding: 0 0 20px 20px;
  text-align: left;
  font-weight: 600;
  position: relative;
  z-index: 10;
}
.profile-card_media_content h4 {
  font-size: 16px;
  text-align: left;
  color: #fff;
  padding-bottom: 4px;
}
.profile-card_media_content h5 {
  font-size: 10px;
  text-align: left;
  text-transform: uppercase;
  color: #fff;
}
.profile-card_content {
  padding: 45px 30px 20px;
  position: relative;
  z-index: 2;
}
.profile-card_content h4 {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
}
.profile-card-stats {
  position: absolute;
  bottom: 15px;
  left: 130px;
  right: 30px;
  padding: 15px 0;
  background: #fff;
  border-radius: 2px;
  z-index: 20;
  box-shadow: 0 15px 20px rgb(0 0 0 / 7%);
}
.profile-card-stats:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 1px;
  top: 10px;
  bottom: 10px;
  background: #ddd;
}
.profile-card-stats li {
  float: left;
  width: 50%;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
}
.profile-card-stats li span {
  padding-right: 6px;
}
.abs_bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 150px;
  background-attachment: scroll;
  background-position: right;
  background-origin: content-box;
  /* background-image: url("../images/city-bg.png"); */
  opacity: 0.6;
  z-index: 1;
}
.profile-card-footer {
  border-top: 1px solid #eee;
  background: #fff;
}
.profile-card-social {
  float: right;
}
.profile-card-social li,
.profile-card-social li a {
  float: left;
}
.profile-card-social li a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-left: 1px solid #eee;
  background: #fff;
}
.profile-card-social li a:hover {
  background: #f9f9f9;
}
.profile-card-footer_title {
  float: left;
  padding-left: 30px;
  height: 50px;
  line-height: 50px;
  font-weight: 600;
}
.pc_contacts {
  padding: 10px 0;
}
.pc_contacts ul {
  background: #fff;
  padding: 6px 20px;
  box-shadow: 0 15px 20px rgb(0 0 0 / 3%);
}
.pc_contacts ul li {
  text-align: left;
  width: 100%;
  padding: 15px 0;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
}
.pc_contacts ul li:first-child {
  border-bottom: 1px solid #eee;
}
.pc_contacts ul li span {
  padding-right: 10px;
}
.ideaboxWeather:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-size: cover;
  background-attachment: scroll;
  background-position: center;
  background-repeat: repeat;
  background-origin: content-box;
  /* background-image: url("../images/bg/7.jpg"); */
  z-index: 1;
}
/*------ video ------------------------------------------------------*/
.dark-bg {
  background: #191919;
}
.video-section-title {
  margin-bottom: 30px;
}
.video-section-title:before {
  content: "";
  position: absolute;
  left: 40%;
  right: 200px;
  height: 1px;
  bottom: 12px;
  background: rgba(255, 255, 255, 0.1);
}
.video-section-title h2 {
  text-align: left;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  float: left;
}
.video-section-title h4 {
  float: left;
  margin-left: 20px;
  color: #bfc2c9;
  font-weight: 600;
  padding-top: 14px;
}
.video-section-title a {
  float: right;
  color: #fff;
  padding: 10px 40px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 10px;
  text-transform: uppercase;
}
.video-section-title a i {
  margin-left: 10px;
}
.video-holder-title .post-category-marker {
  position: absolute;
  right: 20px;
  top: 26px;
}
.video-holder {
  height: 370px;
  overflow: hidden;
}
.vh-preloader {
  position: absolute;
  top: 66px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  z-index: 20;
  display: none;
}
.big_prom {
  position: absolute;
  z-index: 10;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: #fff;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -30px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 16px rgb(255 255 255 / 30%);
  transition: all 0.3s ease-in-out;
}
.video-holder:hover .big_prom {
  box-shadow: 0px 0px 0px 26px rgb(255 255 255 / 30%);
}
.video-holder-title {
  padding: 15px 20px;
  background: #222;
  z-index: 20;
}
.video-holder-title_item {
  font-size: 18px;
  text-align: left;
  color: #fff;
  font-weight: 600;
}
.video-holder-title_item a {
  color: #fff;
}
.video-main-cont {
  float: left;
  width: calc(100% - 430px);
  position: relative;
}
.video-links-wrap {
  position: absolute;
  right: 0;
  top: 0;
  width: 420px;
  max-height: 516px;
  overflow: auto;
  padding: 0 16px 0 0px;
}
.video-links-wrap .ps__rail-y:before {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
.video-links-wrap .video-item {
  cursor: pointer;
}
.video-item {
  margin-bottom: 10px;
}
.video-item:last-child {
  margin-bottom: 0;
}
.video-item-img {
  overflow: hidden;
}
.video-item-title {
  position: absolute;
  bottom: 0;
  z-index: 20;
  left: 0;
  right: 0;
  padding: 10px 20px 20px;
}
.play-icon {
  position: absolute;
  left: 41%;
  width: 40px;
  height: 40px;
  top: 34%;
  line-height: 40px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 6px rgb(255 255 255 / 30%);
  z-index: 10;
  font-size: 9px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.video-item_active .play-icon,
.play-icon:hover {
  color: #fff;
  box-shadow: 0px 0px 0px 10px rgb(255 255 255 / 30%);
}
.video-item-img:before,
.grid-post-media.gpm_sing:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.9) 90%
  );
}
.grid-post-media.gpm_sing:before {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 90%
  );
}
.video-item .post-category-marker {
  position: absolute;
  right: 20px;
  top: 30px;
  z-index: 10;
}
.video-item img {
  transition: all 0.3s ease-in-out;
  transform: scale(1);
}
.video-item:hover img {
  transform: scale(1.1);
}
.video-item-title h4 {
  text-align: left;
  color: #fff;
  float: left;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
}
.video-item-title h4 a {
  color: #fff;
}
.video-date {
  float: left;
  color: #fff;
  font-size: 11px;
  margin-top: 6px;
  font-weight: 500;
}
.video-date i {
  margin-right: 10px;
}
.video_carousel-wrap {
  margin-top: 40px;
}
.video_carousel-container {
  padding: 10px 10px;
}
.video_carousel_title h4 {
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #fff;
}
.vc-item-title {
  padding: 10px 0 0 0;
}
.no-bottom-padding {
  padding-bottom: 0;
}

.video_carousel .video-item {
  border-radius: 4px;
  overflow: hidden;
  height: 155px;
}
.cc_btn {
  position: absolute;
  bottom: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  margin-bottom: -18px;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  background: #ed143d;
  z-index: 1;
}
.cc-prev {
  left: 0px;
}
.cc-next {
  right: 0px;
}
.vc-pagination {
  position: absolute;
  right: 40px;
  top: 24px;
}
.pag-style .swiper-pagination-bullet,
.multi-pag .swiper-pagination-bullet {
  opacity: 1;
  background: #fff;
  margin: 0 8px;
  display: inline-block;
  width: 6px;
  height: 6px;
  position: relative;
  border-radius: 100%;
  transition: all 300ms ease-out;
}
.multi-pag .swiper-pagination-bullet {
  float: left;
}
.pag-style .swiper-pagination-bullet:before,
.sgs-pagination .swiper-pagination-bullet:before,
.multi-pag .swiper-pagination-bullet:before,
.hero-carousel-pag .swiper-pagination-bullet:before {
  content: "";
  position: absolute;
  left: -7px;
  top: -7px;
  right: -7px;
  bottom: -7px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 1px solid rgba(255, 255, 255, 0.4);
  transition: all 300ms ease-out;
  transform: scale(0);
}
.pag-style .swiper-pagination-bullet.swiper-pagination-bullet-active:before,
.sgs-pagination
  .swiper-pagination-bullet.swiper-pagination-bullet-active:before,
.multi-pag .swiper-pagination-bullet.swiper-pagination-bullet-active:before,
.hero-carousel-pag
  .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
  transform: scale(1);
}
.resp-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin-bottom: 20px;
}
.resp-video iframe,
.resp-video object,
.resp-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*------  categoties ------------------------------------------------------*/
.post-category-marker {
  padding: 6px 13px;
  border-radius: 2px;
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  background: rgba(0, 0, 0, 0.6);
}
/*------404------------------------------------------------*/
.error-section {
  padding-top: 20px;
}
.error-wrap {
  padding: 0 0 40px 0;
}
.error-wrap h2 {
  font-size: 194px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 10px;
  position: relative;
  font-family: "Quicksand", sans-serif;
}
.error-wrap h2:before {
  content: "";
  position: absolute;
  left: 50%;
  width: 50px;
  height: 2px;
  bottom: 30px;
  margin-left: -30px;
}
.error-wrap p {
  color: #fff;
  font-size: 12px;
  text-align: center;
}
.error-wrap form {
  margin: 30px auto 20px;
  max-width: 550px;
  overflow: hidden;
  border-radius: 2px;
  position: relative;
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.2);
}
.error-wrap form.custom-form input {
  float: left;
  width: 100%;
  border: none;
  background: #fff;
  height: 50px;
  padding: 0 100px 0 20px;
  z-index: 1;
  margin: 0;
}
.error-wrap form .search-submit {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 50px;
  line-height: 50px;
  z-index: 2;
  color: #fff;
  border: none;
  cursor: pointer;
}
.error-wrap form .search-submit:hover {
  background: #292929;
}
.error-wrap a.btn {
  display: inline-table;
  margin-top: 10px;
  float: none;
}
/*------  contacts------------------------------------------------------*/
.contacts-list {
  background: #f9f9f9;
  border: 1px solid #eee;
  padding: 15px 20px;
}
.contacts-list li {
  float: left;
  width: 100%;
  height: 45px;
  line-height: 45px;
  border-bottom: 1px dashed #ddd;
  font-weight: 500;
}
.contacts-list li:last-child {
  border: none;
}
.contacts-list li span {
  float: left;
  text-align: left;
  min-width: 80px;
  color: #666;
}
.contacts-list li span i {
  margin-right: 7px;
  font-size: 14px;
}
.contacts-list li a {
  margin-left: 20px;
  color: #333;
  text-align: left;
  float: left;
}
.cs-title {
  margin-right: 20px;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  top: 10px;
  font-size: 11px;
  color: #000;
}
.contacts-opt {
  margin: 10px 0 40px 0;
}
.contact-social {
  float: right;
  margin: 20px 0 40px;
}
.contact-social li,
.contact-social .cs-title,
.contact-social ul {
  float: left;
}
.contact-social li ul {
  border: 1px solid #eee;
}
.contact-social li a {
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin-left: 2px;
  border-radius: 4px;
  float: left;
  color: #fff;
  background: #333;
}
.contact-social li a:hover {
  background: #222;
}
/*------ shop-------------------------------------------------*/
.det-box h3 {
  float: left;
  text-align: left;
  font-size: 16px;
  padding-bottom: 8px;
  font-weight: 700;
  width: 100%;
}
.det-box {
  float: left;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
}
.det-box-media {
  position: relative;
  display: block;
  z-index: 8;
  overflow: hidden;
  border-radius: 6px 6px 0 0;
}
.det-box-ietm {
  background: #fff;
  padding: 25px 30px;
  border-radius: 0 0 6px 6px;
  border: 1px solid #eee;
  border-top: none;
}
.det-box-media:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #292929;
  opacity: 0;
  z-index: 2;
  transition: all 0.3s ease-in-out;
}
.det-box-media span {
  z-index: 5;
  position: absolute;
  left: 50%;
  top: 40%;
  opacity: 0;
  height: 36px;
  line-height: 36px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 10px;
  width: 130px;
  color: #fff;
  border-radius: 4px;
  margin: -13px 0 0 -65px;
  transition: all 0.3s ease-in-out;
}
.det-box-media:hover:before {
  opacity: 0.4;
}
.det-box-media:hover span {
  top: 50%;
  opacity: 1;
}
.shop-item-grid {
  padding: 130px 30px;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
}
.listing-item-grid .d-gr-sec:before {
  opacity: 0.7;
}
.shop-item-grid_title {
  position: absolute;
  left: 30px;
  bottom: 14px;
  right: 30px;
  z-index: 10;
  text-align: left;
}
.shop-item-grid_title h3,
.shop-item-grid_title h3 a,
.shop-item-grid:hover p {
  color: #fff;
}
.shop-item-grid_title p {
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.3s ease-in-out 0s;
}
.shop-item-grid_title h3 {
  font-weight: 700;
  padding-bottom: 5px;
  margin-bottom: 10px;
  font-size: 16px;
  position: relative;
}
.shop-item-grid_title h3:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 20px;
  height: 2px;
}
.shop-item-grid .shop-counter {
  position: absolute;
  left: 20px;
  top: 30px;
  z-index: 10;
  padding: 8px 16px;
  color: #292929;
  border-radius: 2px;
  font-size: 11px;
  font-weight: 600;
}
.shop-item-grid .shop-counter span {
  padding-right: 6px;
}
.d-gr-sec {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.95) 100%
  );
}
.shop-header {
  background: #fff;
  padding: 15px 30px;
  box-shadow: 0 19px 20px rgba(58, 87, 135, 0.08);
  margin-bottom: 20px;
}
.hero-wrap_custom_title {
  margin-top: 70px;
}
.hero-search {
  background: #fff;
  border-radius: 2px;
  padding: 80px 30px 30px;
  box-shadow: 0px 0px 0px 6px rgb(255 255 255 / 30%);
}
.hero_prwarp {
  padding: 0 0 0 60px;
}
.hero_prwarp-title {
  position: absolute;
  left: 0;
  top: 20px;
  font-size: 11px;
  color: #666;
  font-weight: 600;
}
.hero-search-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 30px;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #eee;
  text-align: left;
  color: #292929;
  font-weight: 600;
  font-size: 11px;
  text-transform: uppercase;
}
.hero-search-title i {
  margin-right: 15px;
  font-size: 14px;
  position: relative;
  top: 1px;
}
.hero-search-submit {
  height: 50px;
  line-height: 50px;
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
  -webkit-appearance: none;
  font-family: "Quicksand", sans-serif;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
  background: #292929;
}
.hero-search-submit:hover {
  color: #292929;
}
.hero-search-submit i {
  margin-right: 10px;
}
.shop-header .nice-select {
  border: 1px solid #eee;
  background: #f5f5f5;
}
.box-widget-content .irs-single {
  color: #fff;
}
.shop-header_opt {
  float: right;
  width: 200px;
}
.shop-header h4 {
  float: left;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  top: 15px;
}
.price-rage-wrap.shop-rage-wrap {
  padding-left: 0;
  padding-right: 70px;
}
.shop-rage-wrap .srw_btn {
  position: absolute;
  right: 0;
  width: 60px;
  height: 28px;
  line-height: 28px;
  color: #fff;
  top: 8px;
  font-size: 10px;
  font-weight: 600;
}
.shop-rage-wrap .srw_btn:hover {
  background: #222;
}
.radio-group input[type="radio"] {
  position: absolute;
  visibility: hidden;
  display: none;
}
.radio-group label {
  color: #666;
  float: left;
  padding: 12px 0;
  cursor: pointer;
  width: 33.3333333%;
  font-weight: 500;
  font-size: 11px;
}
.radio-group input[type="radio"]:checked + label {
  color: #292929;
}
.radio-group label + input[type="radio"] + label {
  border-left: 1px solid #e1e1e1;
}
.radio-group {
  border: 1px solid #e1e1e1;
  background: #f2f2f2;
  border-radius: 2px;
  overflow: hidden;
}
.reviews_counter_wrap {
  float: left;
  margin-bottom: 15px;
}
.reviews_counter_wrap_text,
.reviews_counter_wrap .star-rating {
  float: left;
}
.reviews_counter_wrap_text {
  font-size: 11px;
  color: #666;
  font-weight: 500;
  margin-top: 1px;
}
.reviews_counter_wrap .star-rating i {
  margin: 0 1px;
  font-size: 10px;
}
.reviews_counter_wrap .star-rating {
  margin-right: 10px;
}
.grid-item_price_item {
  font-size: 11px;
  position: relative;
  float: left;
  font-weight: 600;
  text-transform: uppercase;
  color: #777;
  top: 0px;
}
.grid-item_price_item strong {
  font-size: 17px;
  color: #333;
  position: relative;
  top: 1px;
  margin-left: 7px;
  font-weight: 700;
}
.grid-item_price {
  border-top: 1px solid #eee;
  padding-top: 15px;
}
.grid-item_price .add_cart {
  float: right;
  padding: 8px 10px;
  font-size: 10px;
  font-weight: 600;
  cursor: pointer;
  background: #222;
  color: #fff;
  border-radius: 4px;
  text-transform: uppercase;
}
.grid-item_price .add_cart i {
  margin-right: 6px;
}
.grid-item_price .add_cart:hover {
  color: #ccc;
}
.detbox_notifer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px 15px;
  font-size: 11px;
  font-weight: 600;
  z-index: 50;
  color: #fff;
  box-shadow: 0px 0px 0px 6px rgb(255 255 255 / 40%);
}
.shop_post-content {
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  margin: 20px 0;
}
.det-box-ietm.dbi_shop {
  padding-bottom: 15px;
}
.shop-header-title {
  background: #f9f9f9;
  padding: 30px 35px 20px;
  border: 1px solid #eee;
  border-bottom: none;
}
.shop-header-title .qty_btn input {
  background: #fff;
}
.shop-header-title h2 {
  text-align: left;
  font-size: 22px;
  font-weight: 700;
  border-bottom: 1px solid #ddd;
  padding-bottom: 15px;
}
.shop-header-title h2 span {
  font-size: 12px;
  color: #666;
  font-weight: 500;
  margin-left: 10px;
  position: relative;
  top: -12px;
}
.shop-header-title_opt,
.shop-header-title_opt li {
  float: left;
}
.shop-header-title_opt {
  margin-top: 10px;
}
.shop-header-title_opt li,
.shop-header-title .single_post-opt li {
  margin-right: 20px;
}
.shop-header-title .single_post-opt {
  margin-top: 16px;
}
.shop-header-title_opt li .reviews_counter_wrap {
  margin-top: 5px;
}
.qty_btn {
  float: right;
  margin-top: 20px;
}
.qty_btn .btn {
  border: none;
  margin-top: 0;
  height: 48px;
  padding: 0 36px;
  cursor: pointer;
  line-height: 48px;
}
.qty_btn input {
  float: left;
  border: 1px solid #eee;
  margin-right: 10px;
  background: #f9f9f9;
  position: relative;
  height: 50px;
}
.content-table table {
  width: 100%;
  margin-top: 20px;
  float: left;
}
.content-table table td,
.content-table table th {
  padding: 18px 30px;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
}
.content-table table td,
.content-table table th {
  border-left: 1px solid #e2e6e7;
}
.content-table table td:first-child,
.content-table table th:first-child {
  text-align: left;
  border-left: none;
}
.content-table table.align-left td:first-child,
.content-table table.align-left th:first-child {
  text-align: left;
}
.content-table table tr:nth-child(odd) {
  background: #f5f5f5;
}
.mar-top {
  margin-top: 30px;
}
.leave-rating-wrap {
  top: -6px;
  margin: 0 0 20px 0;
}
.leave-rating,
.leave-rating-title {
  float: left;
}
.leave-rating-title {
  margin-right: 10px;
  font-size: 12px;
  color: #000;
  position: relative;
  font-weight: 600;
}
.leave-rating input[type="radio"] {
  display: none;
}
.custom-form .leave-rating label {
  font-size: 14px;
  float: right;
  letter-spacing: 4px;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 500;
  width: auto;
}
.leave-rating label:hover:before,
.leave-rating label.selected:before {
  font-weight: 900;
}
.leave-rating:hover input[type="radio"]:checked ~ label:before,
.leave-rating input[type="radio"]:checked ~ label:before,
.leave-rating label:hover ~ label:before {
  font-weight: 900 !important;
}
.checkout-table {
  border: 1px solid #eee;
  float: left;
  width: 100%;
  font-size: 12px;
  background: #fff;
  font-weight: 600;
}
.checkout-table > tbody > tr > td,
.checkout-table > tbody > tr > th,
.checkout-table > tfoot > tr > td,
.checkout-table > tfoot > tr > th,
.checkout-table > thead > tr > td,
.checkout-table > thead > tr > th {
  border-top: 1px solid #eee;
  padding: 12px;
  border-right: 1px solid #eee;
}
.checkout-table tr td:nth-child(odd) {
  background: #f9f9f9;
}
.checkout-table tbody tr td:first-child,
.checkout-table tbody tr th:first-child {
  max-width: 56px;
  background: #fff;
  border-right: 1px solid #eee;
}
.pr-remove {
  vertical-align: middle !important;
  text-align: center;
}
.order-money {
  font-size: 12px;
}
.order-count {
  border: none;
  width: 60px;
  height: 50px;
  text-align: center;
  font-weight: bold;
}
.pr-remove {
  font-size: 10px;
}
.pr-remove:hover i {
  color: #292929;
}
.product-name {
  font-weight: 500;
}
.coupon-holder {
  margin-top: 30px;
  padding: 12px 0 0 0;
}
.coupon-holder input {
  float: left;
  margin-bottom: 20px;
  font-size: 14px;
  height: 50px;
  border-radius: none;
  position: relative;
  z-index: 20;
  padding-left: 18px;
  color: #000;
  border: 0;
  border: 1px solid #eee;
  background: #fff;
}
.coupon-holder button {
  float: left;
  height: 50px;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 0 40px;
  font-size: 10px;
  font-weight: 600;
  border-radius: 4px;
  background: #292929;
  -webkit-appearance: none;
  font-family: "Quicksand", sans-serif;
  transition: all 0.3s ease-in-out;
}
.coupon-holder button.btn-uc {
  float: right;
}
.coupon-holder button:hover {
  color: #ccc;
}
h4.cart-title {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
  color: #000;
}
h4.cart-title span {
  padding: 0 2px;
  color: #555;
}
.cart-totals button {
  float: right;
  margin-top: 40px;
  height: 48px;
  border: none;
  color: #fff;
  width: 100%;
  cursor: pointer;
  padding: 0 20px;
  font-size: 11px;
  font-weight: 600;
  -webkit-appearance: none;
  position: relative;
  border-radius: 4px;
  z-index: 2;
  font-family: "Quicksand", sans-serif;
  transition: all 0.3s ease-in-out;
}
.cart-totals button:hover {
  background: #fff;
}
.cart-totals:before {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  z-index: 2;
  box-shadow: 0px 0px 0px 20px rgba(255, 255, 255, 0.03);
}
.cart-totals {
  padding: 66px 50px;
  background: #292929;
}
.cart-totals:after {
  font-family: Font Awesome\5 Pro;
  content: "\f83e";
  position: absolute;
  right: 50px;
  top: 30px;
  font-size: 44px;
  color: rgba(255, 255, 255, 0.1);
  z-index: 1;
}
.cart-totals h3 {
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  padding-bottom: 20px;
  color: #fff;
}
.total-table {
  width: 100%;
  float: left;
  position: relative;
  z-index: 2;
}
.total-table tr {
  font-size: 14px;
  text-align: right;
}
.total-table th,
.total-table tr {
  padding: 15px 0;
  color: #fff;
}
.total-table th {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  text-align: left;
  font-size: 12px;
}
.tab-content {
  width: 100%;
  display: none;
  float: left;
}
.tab {
  width: 100%;
  float: left;
}
.first-tab {
  display: block;
}
.shop-tabs-menu {
  margin-bottom: 20px;
}
.shop-tabs-menu,
.shop-tabs-menu li {
  float: left;
}
.shop-tabs-menu li {
  margin-right: 10px;
  position: relative;
  z-index: 3;
}
.shop-tabs-menu li a {
  padding-top: 12px;
  padding-bottom: 12px;
  background: #f5f5f5;
}
.shop-tabs .content-table table {
  margin: 0 0 20px;
}
.shop-tabs-menu:before,
.shop-tabs-menu:after {
  content: "";
  position: absolute;
  left: 20%;
  height: 1px;
  right: 0;
  background: #eee;
  top: 50%;
  z-index: 1;
}
.shop-tabs-menu:after {
  left: auto;
  width: 40px;
  z-index: 2;
}
.item-related {
  text-align: left;
}
.item-related_content {
  padding: 15px 20px;
  background: #f9f9f9;
  border: 1px solid #eee;
  border-top: none;
}
.post-related h6 {
  margin-bottom: 20px;
}
.item-related img {
  width: 100%;
  height: auto;
}
.item-related h3 {
  float: left;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  padding-bottom: 4px;
}
.item-related {
  padding-top: 15px;
  padding-bottom: 15px;
}
.item-related_title {
  float: left;
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: #292929;
  margin: 30px 0 20px;
  position: relative;
}
.item-related_title:before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 30px;
  height: 3px;
}
.about-img img {
  position: relative;
  z-index: 10;
}
.about-img-hotifer {
  position: absolute;
  right: -60px;
  bottom: 20px;
  width: 280px;
  padding: 25px 30px;
  z-index: 11;
  border-radius: 4px;
  color: #fff;
  text-align: left;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
}
.about-img-hotifer:before {
  font-family: Font Awesome\5 Pro;
  content: "\f10e";
  bottom: 30px;
  right: 20px;
  position: absolute;
  color: #fff;
  font-size: 19px;
  font-weight: bold;
}
.about-wrap p {
  font-size: 12px;
}
.about-img-hotifer p {
  color: #fff;
  font-size: 13px;
  margin-bottom: 10px;
  text-align: left;
  font-weight: 600;
}
.about-wrap .btn {
  margin-top: 30px;
}
.pad-con {
  padding-left: 50px;
}
/*------  Responsive ------------------------------------------------------*/
@media only screen and (max-width: 1064px) {
  .aside-panel,
  .header_news-ticker-wrap {
    display: none;
  }
  .cen-align-container {
    padding-left: 0;
    border-left: none;
  }
  .pad-con {
    padding: 30px 0 0;
  }
  .nav-button-wrap {
    float: right;
    height: 70px;
    width: 70px;
    cursor: pointer;
    position: relative;
    display: block;
    border-left: 1px solid #eee;
    left: 24px;
  }
  .nav-button {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 20px;
    margin-left: -11px;
    margin-top: -6px;
  }
  .nav-button span {
    float: left;
    width: 100%;
    height: 2px;
    background: #000;
    margin-bottom: 4px;
    border-radius: 4px;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
  .nav-button span:first-child {
    width: 50%;
  }
  .main-menu nav ul {
    display: none;
  }
  .main-menu {
    position: absolute;
    top: 70px;
    right: -20px;
    width: 300px;
    z-index: 50;
    padding: 15px 10px;
    background: #292929;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .vismobmenu {
    opacity: 1;
    right: 0;
    visibility: visible;
  }
  .menusb {
    display: block;
    float: left;
    position: relative;
    width: 100%;
    overflow: hidden;
  }
  .main-menu .menusb ul {
    display: block;
    float: left;
    position: relative;
    width: 100%;
  }
  .menusb li {
    margin: 0;
    float: left;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 0 20px;
  }
  .menusb li i {
    position: absolute;
    right: 18px;
    top: 12px;
  }
  .menusb li i:before {
    content: "\f0da";
  }
  .menusb a {
    float: left;
    width: 100%;
    padding: 8px 5px;
    position: relative;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    color: #fff;
    line-height: 20px;
    text-align: left;
  }
  .menusb a.back {
    padding-left: 24px;
  }
  .menusb a.back:before {
    font-family: Font Awesome\5 Pro;
    content: "\f0d9";
    font-size: 14px;
  }
  .sidebar-content {
    border-left: none;
    margin-top: 30px;
  }
  .box-widget {
    margin: 0 0 30px;
    padding-left: 0;
  }
  .main-footer,
  #footer-twiit li,
  #footer-twiit p.tweet,
  .slider_widget .grid-post-media_title .video-date,
  .slider_widget .grid-post-media_title .post-opt,
  .footer-widget,
  .fs-carousel-wrap_title,
  .about-img-hotifer {
    width: 100%;
  }
  #footer-twiit li,
  .footer-widget,
  .fs-carousel-wrap_title,
  .topbar-social,
  .about-img-hotifer {
    float: left;
  }
  .topbar-social {
    margin-left: 10px;
  }
  .post-opt li {
    float: none;
    display: inline-block;
  }
  .footer-widget {
    margin-bottom: 20px;
  }
  .fs-carousel-wrap {
    padding-left: 0;
  }
  .fs-carousel-wrap_title {
    position: relative;
    padding: 120px 50px;
  }
  .video-main-cont {
    width: calc(100% - 340px);
  }
  .video-links-wrap {
    width: 330px;
  }
  .picker-wrap-container,
  .about-img,
  .about-img-hotifer,
  .cart-totals {
    margin-top: 30px;
  }
  .profile-card-wrap {
    margin-bottom: 30px;
  }
  .gallery-item {
    width: 50%;
  }
  .about-img-hotifer {
    position: relative;
    right: 0;
    bottom: 0;
  }
}
@media only screen and (max-width: 856px) {
  .main-register-wrap {
    padding-left: 0;
  }
  .main-register_bg,
  .hero-slider_controls-wrap,
  .spc_column:before {
    display: none;
  }
  .video-main-cont,
  .video-links-wrap,
  .ajax-nav,
  .video-section-title a,
  .single-post-content_column,
  .steader_opt_abs,
  .gallery-items,
  .qty_btn {
    width: 100%;
  }
  .video-section-title a {
    margin-top: 10px;
  }
  .qty_btn .btn,
  .shop-header-title .single_post-opt {
    float: left;
  }
  .video-links-wrap {
    position: relative;
    float: left;
    margin-top: 10px;
    padding: 0 16px 0 0px;
  }
  .hero-item {
    padding-bottom: 100px;
  }
  .hero-slider-wrap {
    height: 450px;
  }
  .ajax-nav {
    position: relative;
    float: left;
    clear: both;
    bottom: 0;
    background: #f9f9f9;
    margin: 46px 0 20px;
  }
  .fs-slider_btn {
    top: auto;
    bottom: 40px;
    right: auto;
    left: 40px;
  }
  .fs-slider-button-prev {
    margin-top: 0;
  }
  .fs-slider-button-next {
    margin-left: 50px;
  }
  .spc_column {
    padding-left: 0;
  }
  .ver-share {
    margin: 10px 0 20px;
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
  }
  .single-post-content_column,
  .ver-share .share-title,
  .gallery-items {
    position: relative;
    float: left;
  }
  .ver-share .share-container {
    width: auto;
    margin: -6px 0 0 20px;
  }
  .ver-share .share-container .share-icon {
    border-left: 1px solid #eee;
    border-bottom: none;
  }
  .ver-share .share-container .share-icon:last-child {
    border-left: 1px solid #eee;
  }
  .ver-share .share-container .share-icon:first-child {
    border: none;
  }
  .steader_opt_abs {
    position: relative;
    float: left;
    margin: 40px 0 30px;
  }
}
@media only screen and (max-width: 630px) {
  .spc_column:before,
  .comment-author:before,
  .single-post-nav:before {
    display: none;
  }
  .video-holder-title .post-category-marker {
    right: auto;
    left: 20px;
    top: -16px;
  }
  .logo-holder {
    top: 16px;
    left: 10px;
    height: 15px;
  }
  .header-inner,
  .show-cart,
  .srf_btn,
  .search_btn,
  .nav-button-wrap {
    height: 50px;
  }
  .show-cart,
  .srf_btn,
  .search_btn,
  .nav-button-wrap {
    width: 50px;
    line-height: 50px;
  }
  .header-inner .container,
  .top-bar .container,
  .picker-wrap .list-post .list-post-media,
  .picker-wrap .list-post .list-post-content,
  .list-post-media,
  .list-post-content,
  .subfooter-nav,
  .single-post-header .post-opt,
  .fontSize,
  .spn_box,
  .spn_box_img,
  .share-holder.hor-share,
  .profile-card-footer_soc,
  .load-more_btn,
  .social-widget a,
  .gallery-item {
    width: 100%;
  }
  .picker-wrap .list-post .list-post-media,
  .picker-wrap .list-post .list-post-content,
  .subfooter-nav,
  .single-post-header .post-opt,
  .print-btn,
  .spn_box_img,
  .share-holder.hor-share,
  .load-more_btn {
    float: left;
  }
  .social-widget a {
    float: left;
    margin: 0 0 10px 0;
  }
  .list-post-content {
    padding: 3px 0 0 0;
  }
  .single-post-header .post-opt,
  .share-holder.hor-share {
    margin-top: 10px;
  }
  .picker-wrap .list-post .list-post-media,
  .list-post-media {
    margin-bottom: 20px;
  }
  .nav-button span {
    float: left;
    width: 100%;
    height: 2px;
    background: #000;
    margin-bottom: 4px;
    border-radius: 4px;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
  .show-cart_count {
    bottom: 6px;
    left: 6px;
  }
  #wrapper {
    padding-top: 120px;
  }
  .main-menu,
  .header-cart_wrap {
    top: 50px;
  }
  .header-cart_wrap {
    right: 0;
    width: 300px;
  }
  .main-footer {
    padding: 60px 0 0;
  }
  .footer-bottom {
    margin-top: 30px;
  }
  .subfooter-nav li {
    float: left;
    margin: 0 10px 0 0;
  }
  .hero-item h2,
  .hero-section_title h2 {
    font-size: 25px;
    line-height: 32px;
  }
  .scroll-down-wrap.scw_transparent {
    bottom: -50px;
    left: 0;
    right: auto;
  }
  .hero-section {
    padding: 150px 0 80px;
  }
  .hero-item h4 {
    font-size: 12px;
    line-height: 20px;
  }
  .single-post-header h1 {
    font-size: 20px;
  }
  .single-post-nav_prev,
  .single-post-nav_next {
    padding: 0;
  }
  .single-post-nav_prev,
  .cookie-info-bar_title {
    margin-bottom: 20px;
  }
  .spn_box_img {
    position: relative;
    margin-bottom: 20px;
  }
  .tags-title {
    margin: 10px 20px 20px 0;
  }
  .comment {
    padding: 0 0 30px 0;
  }
  .comment-author,
  .comment-author img {
    width: 40px;
    height: 40px;
    z-index: 20;
  }
  .comment-author {
    top: 20px;
    left: 30px;
  }
  .comment-body h4 {
    margin-left: 50px;
  }
  .comment_reply {
    width: calc(100% - 40px);
  }
  .comment_reply:before {
    top: -10px;
    left: -30px;
    width: 20px;
    height: 20px;
  }
  .profile-card-footer_soc {
    border-top: 1px solid #eee;
  }
  .shop-header-title .single_post-opt {
    margin-top: 0;
  }
  .shop-header-title {
    padding: 30px 25px 20px;
  }
}
@media only screen and (-webkit-min-device-pixel-ratio: 2) {
  .bg {
    background-attachment: scroll !important;
    -webkit-background-size: cover;
    background-position: center;
  }
}
.bg-gray {
  background: #001a3f;
}
.p-2 {
  padding: 11px;
}
.rounded {
  border-radius: 6px;
}
.clearfix {
  clear: both;
}
.top-section {
    height: 60px;
    position: relative;
    margin-bottom: 49px;
}
/*.top-section {
  background: green url(images/topbg.jpg);
  height: 120px;
  position: relative;
  margin-bottom: 49px;
}*/
.circle-rounded {
  border-radius: 100%;
  border: 5px solid #fff;
}
.profile-img {
  position: absolute;
  /* bottom: -37px; */
  left: 0;
  right: 0;
  z-index: 1;
}
.error-msg {
    display: block;
    margin-bottom: 10px;
    font-size: 0.7rem;
    color: red;
}
.profile-img img {
    height: 100px;
    width: 100px;
    object-fit: cover;
}
.text-center {
  text-align: center !important;
}
.social-icon svg {
  height: 34px;
  fill: #fff;
}
.social-icon {
  font-size: 10px;
  text-align: center;
  padding: 10px;
}
.social-icon span {
  margin: 10px;
  display: inline-block;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.justify-content-between {
  justify-content: center;
}

.mt-2 {
  margin-top: 10px;
}
.mb-2 {
  margin-bottom: 10px;
}
/* @media (min-width: 768px) {
  .content {
    display: none;
    background: #fff;
  }
  body {
    background: #fff;
  }
} */
.msg-content {
  color: #cc6600;
  left: 0;
  right: 0;
  font-size: 30px;
  position: absolute;
  bottom: 50%;
}
.ps-0 {
  padding-left: 0px;
}
.pe-0 {
  padding-right: 0px;
}
.lg-actions .lg-next,
.lg-actions .lg-prev {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.justify-content-center {
  justify-content: center;
}
.first-section {
  border-radius: 0px;
  position: relative;
  margin-top: 70px;
  z-index: 0;
}
.border {
  border: 1px solid #54585e;
}
.mb-0 {
  margin-bottom: 0;
}
.pb-0 {
  padding-bottom: 0;
}
