.form-shape-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  /* display: flex; */
  /* flex-direction: column; */
}

.form-shape-wrapper .form-shape {
  height: 50%;
  position: relative;
}

.form-shape-wrapper .form-shape:first-child {
  background: linear-gradient(90deg, #e72b2b, #1f375a);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.form-shape-wrapper .form-shape:first-child svg path {
  fill: #f5f5f5;
}

.text-divider {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 14px;
  margin: 30px 0;
}

.text-divider:before {
  margin-right: 10px;
}

.text-divider:after,
.text-divider:before {
  content: "";
  display: block;
  height: 1px;
  flex: 1;
  background: #b3b3b3;
}

.text-divider:after {
  margin-left: 10px;
}

.social-links a i {
  width: 40px;
  font-size: 20px;
  color: #fff;
  height: 40px;
  background: linear-gradient(90deg, #e72b2b, #1f375a);
  border-radius: 20px;
  margin-right: 10px;
}

.social-links a:last-child {
  margin-right: 0;
}

.social-links a:focus,
.social-links a:hover {
  text-decoration: none;
  opacity: 0.8;
}

.social-links a .anticon {
  width: 40px;
  font-size: 20px;
  color: #fff;
  height: 40px;
  background: linear-gradient(90deg, #e72b2b, #1f375a);
  border-radius: 20px;
  margin-right: 10px;
  justify-content: space-around;
}

.social-links a {
  color: #000;
}

.org-logo-container-sm {
  display: flex;
  width: 100%;
  align-content: center;
  justify-content: center;
}
